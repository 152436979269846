import React,{ useState } from "react";
import * as _ from "lodash";
import OptionLabelCompent from "./OptionLabelCompent";
import { optionsData } from "./OptionsData";
import StartFlowCompent from "./StartFlowCompent";

const OptionsCompent = (props) => {
  const terminal = props.terminal;
  const customizedTerminalData = props.customizedTerminalData;

  const [optionStep,setOptionStep] = useState([]);

  const optionSeleted = async (selectedOptionId) => {
    if (selectedOptionId) selectedOptionId *= 1;
    else {
      return;
    }
    const parentData = _.findLast(
      optionsData,
      (optionData) => optionData.id === selectedOptionId
    );

    const parentId = parentData?.parentId;

    if (parentId === null) {
      setOptionStep([selectedOptionId]);
    } else if (parentId !== undefined) {
      const lastIndex = optionStep.indexOf(parentId) + 1;
      setOptionStep((prev) => [...prev.slice(0,lastIndex),selectedOptionId]);
    }
  };

  const getChildrenOptions = (parentId) => {
    const childrenOptions = optionsData.filter(
      (option) => option.parentId === parentId
    );
    const parentData = _.findLast(
      optionsData,
      (option) => option.id === parentId
    );
    const groupName = childrenOptions.map((option) => option.id).join("-");
    return (
      <div>
        {parentData.nextStepTitle ? (
          <h3 className="text-center">{parentData.nextStepTitle}</h3>
        ) : (
          parentData.action && (
            <StartFlowCompent
              action={parentData.action}
              terminal={terminal}
              customizedTerminalData={customizedTerminalData}
            ></StartFlowCompent>
          )
        )}

        <OptionLabelCompent
          options={childrenOptions}
          groupName={groupName}
          optionSeleted={optionSeleted}
        ></OptionLabelCompent>
      </div>
    );
  };

  const renderRootDiv = () => {
    const rootOptions = optionsData.filter(
      (option) => option.parentId === null
    );

    return (
      <div>
        <h3 className="text-center">2. Select type of flow</h3>
        <OptionLabelCompent
          options={rootOptions}
          groupName="root"
          optionSeleted={optionSeleted}
        ></OptionLabelCompent>
      </div>
    );
  };

  const renderChildrenDiv = () => {
    return optionStep.map((optionId) => {
      return (
        <div
          key={optionId}
          className="step-block step col-12 flex-column mb-4"
        >
          {getChildrenOptions(optionId)}
        </div>
      );
    });
  };

  return (
    <div className="step-block col-12 flex-column mb-4">
      {renderRootDiv()}
      {renderChildrenDiv()}
    </div>
  );
};

export default OptionsCompent;
