export async function cardAcquistion_loyalty_add_points(actionService) {
  const card = await actionService.cardAcquisition();

  const cardAlias = card.cardId;
  const originPoints =
    localStorage.getItem(`loyalty-${cardAlias}-points`) * 1 || 0;
  let afterPoints = 0;
  afterPoints = originPoints + 1;
  localStorage.setItem(`loyalty-${cardAlias}-points`, afterPoints);

  const points = localStorage.getItem(`loyalty-${cardAlias}-points`) * 1 || 0;

  return await actionService.showEnableMessage(
    "Loyalty",
    `You have ${points} points in total now.`,
    "Accepted"
  );
}
