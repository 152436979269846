import React from "react";

const TerminalFormCompent = (props) => {
  const { handleCustomizedTerminalDataChange, customizedTerminalData } = props;

  return (
    <div className="mt-3">
      <div className="mb-3">
        <label htmlFor="inputHost" className="form-label me-3">
          Host
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="host"
            id="inlineRadio1"
            value="https://terminal-api-test.adyen.com/sync"
            onChange={handleCustomizedTerminalDataChange}
            defaultChecked
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            TEST
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="host"
            id="inlineRadio2"
            value="https://terminal-api-live.adyen.com/sync"
            onChange={handleCustomizedTerminalDataChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            LIVE
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="inputTerminalId" className="form-label">
          Terminal ID
        </label>
        <input
          value={customizedTerminalData.terminalId}
          type="text"
          className="form-control"
          id="inputTerminalId"
          name="terminalid"
          onChange={handleCustomizedTerminalDataChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="inputApiKey" className="form-label">
          Apikey
        </label>
        <input
          value={customizedTerminalData.apiKey}
          type="text"
          className="form-control"
          id="inputApiKey"
          name="apikey"
          onChange={handleCustomizedTerminalDataChange}
        />
      </div>
    </div>
  );
};

export default TerminalFormCompent;
