import * as _ from "lodash";

export async function payment_donation_donateWithOnboard(actionService) {
  const actionResponse = await actionService.postAction({
    action: "input-confirmation",
    parameters: {
      title: "Would you like to donate?",
      agreeText: "Yes",
      declineText: "No",
    },
  });
  if (_.get(actionResponse, "result") === true) {
    const inputAmountResponse = await actionService.postAction({
      action: "input-amount",
      parameters: {
        title: "Enter the amount",
      },
    });
    const amount = inputAmountResponse.result;

    const paymentResponse = await actionService.cardAcquisitionWithPayment(
      amount
    );

    if (paymentResponse === false) {
      await actionService.showEnableMessage(
        "",
        `Cannot get your cardAlias`,
        "Declined"
      );
    }

    if (paymentResponse.result === "Success") {
      const actionResponse = await actionService.postAction({
        action: "input-confirmation",
        parameters: {
          title: "Thank you for your donation",
          content: "\n\n\nDo you want to receive updates from us?",
          agreeText: "Yes",
          declineText: "No",
        },
      });
      let title = "Thanks";
      let content = "Thank you for your registration";
      let icon = "Accepted";
      if (_.get(actionResponse, "result") === true) {
        content = "Thank you for your registration";
      } else {
        content = "Thank you for your purchase message";
      }
      await actionService.showEnableMessage(title, content, icon);
    }
  } else {
    await actionService.showConfirmationMessage(
      "Thanks",
      "\n\n\nThank you for your purchase message"
    );
  }
}
