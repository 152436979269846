import React from "react";
import Header from "./Header";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import TerminalFlowCompent from "./TerminalFlow/TerminalFlowCompent";
import EcomFlowCompent from "./EcomFlow/EcomFlowCompent";
import AdyenWebTest from "./AdyenWebTest";
import AdyenWebLive from "./AdyenWebLive";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header></Header>
                <TerminalFlowCompent />
              </>
            }
          />
          <Route
            path="/ecom"
            element={
              <>
                <Header title="Flow Creator - Ecom"></Header>
                <EcomFlowCompent />
              </>
            }
          />
          <Route path="/adyenWebTest" element={<AdyenWebTest />} />
          <Route path="/adyenWebLive" element={<AdyenWebLive />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
