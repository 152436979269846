import axios from "axios";

export const postAction = async (
  terminal = {
    name: "",
    data: {
      terminalid: "",
      apikey: "",
      host: "",
    },
  },
  data
) => {
  const response = await axios
    .create({
      baseURL: process.env.REACT_APP_API_URL + "/action?terminalId=" + terminal.name,
      headers: {},
    })
    .post("", { ...data, terminalData: terminal.data });
  console.log("[action]response=>", response);
  if (response.data.status === "error") {
    throw new Error(response.data.message || "Server Error.");
  }
  return response.data;
};

export const postFlow = async (
  terminal = {
    name: "",
    data: {
      terminalid: "",
      apikey: "",
      host: "",
    },
  },
  data
) => {
  const response = await axios
    .create({
      baseURL: process.env.REACT_APP_API_URL + "/flow?terminalId=" + terminal.name,
      headers: {},
    })
    .post("", { ...data, terminalData: terminal.data });
  console.log("[flow]response=>", response);
  if (response.data.status === "error") {
    throw new Error(response.data.message || "Server Error.");
  }
  return response.data;
};

export const showStamps = async (
  terminal = {
    name: "",
    data: {
      terminalid: "",
      apikey: "",
      host: "",
    },
  },
  data
) => {
  const response = await axios
    .create({
      baseURL:
        process.env.REACT_APP_API_URL + "/show-loyalty-stamps?terminalId=" + terminal.name,
      headers: {},
    })
    .post("", { ...data, terminalData: terminal.data });
  console.log("[show-loyalty-stamps]response=>", response);
  if (response.data.status === "error") {
    throw new Error(response.data.message || "Server Error.");
  }
  return response.data;
};

export const postCardAcq = async (
  terminal = {
    name: "",
    data: {
      terminalid: "",
      apikey: "",
      host: "",
    },
  }
) => {
  const response = await axios
    .create({
      baseURL: process.env.REACT_APP_API_URL + "/nfc/cardAcq?terminalId=" + terminal.name,
      headers: {},
    })
    .post("", {
      terminalData: terminal.data,
    });

  if (response.data.status === "error") {
    throw new Error(response.data.message || "Server Error.");
  }

  return;
};

export const postGetRoomNumberFromVanderValk = async (
  terminal = {
    name: "",
    data: {
      terminalid: "",
      apikey: "",
      host: "",
    },
  }
) => {
  const response = await axios
    .create({
      baseURL:
        process.env.REACT_APP_API_URL +
        "/nfc/getRoomNumberFromVanderValk?terminalId=" +
        terminal.name,
      headers: {},
    })
    .post("", {
      terminalData: terminal.data,
    });

  if (response.data.status === "error") {
    throw new Error(response.data.message || "Server Error.");
  }

  return;
};
