export async function payment_loyalty_points(actionService) {
  const inputAmountResponse = await actionService.postAction({
    action: "input-amount",
    parameters: {
      title: "Enter the amount",
    },
  });
  const amount = inputAmountResponse.result;
  const addPoints = amount * 200;

  const paymentResponse = await actionService.cardAcquisitionWithPayment(
    amount
  );

  if (paymentResponse === false) {
    await actionService.showEnableMessage(
      "",
      `Cannot get your cardAlias`,
      "Declined"
    );
  }

  if (paymentResponse.result === "Success") {
    const cardAlias = paymentResponse.cardId;
    const originPoints =
      localStorage.getItem(`loyalty-${cardAlias}-points`) * 1;
    let afterPoints = 0;
    afterPoints = originPoints + addPoints;
    if (originPoints) {
      localStorage.setItem(`loyalty-${cardAlias}-points`, afterPoints);
    } else {
      localStorage.setItem(`loyalty-${cardAlias}-points`, addPoints);
    }

    await actionService.showEnableMessage(
      "Loyalty points!",
      `${addPoints} points added, total points: ${afterPoints}`,
      "Accepted"
    );
  }
}
