import React,{ useEffect,useState } from "react";
import * as _ from "lodash";
import TerminalFormCompent from "./TerminalFormCompent";
import OptionsCompent from "./OptionsCompent";
import axios from "../../utils/axios";

const Container = () => {
  const [terminal,setTerminal] = useState("");
  const [customizedTerminalData,setCustomizedTerminalData] = useState({
    terminalid: "",
    apikey: "",
    host: "https://terminal-api-test.adyen.com/sync",
  });
  const [terminalList, setTerminalList] = useState([]);
  
  useEffect(() => {
    const getTerminalList = async () => {
      const { data } = await axios.get("/getTerminalList");
      if (data.terminalList) {
        setTerminalList(data.terminalList);
        data.terminalList.find((item) => {
          if (item.terminalId === "V400m-346591848") {
            setTerminal("V400m-346591848");
          }
        });
      } else if(data.status === 'error') {
        alert("Something went wrong, please try again later");
      }
    };
    getTerminalList();
  }, []);

  console.log('terminalList',terminalList);

  const handleCustomizedTerminalDataChange = (e) => {
    const { name,value } = e.target;
    setCustomizedTerminalData(() => ({
      ...customizedTerminalData,
      [name]: value,
    }));
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="step-block blue col-12 row d-flex flex-column align-items-center mb-5 pt-5">
        <h3 className="text-center">1. Select or add your terminal</h3>
        <div className="col-6 col-md-4 my-3">
          <select
            value={terminal}
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setTerminal(e.target.value)}
          >
            {terminalList.map((item) => (
              <option key={item.terminalId} value={item.terminalId}>
                {item.name}
              </option>
            ))}
            <option value="customization">Customization</option>
          </select>
        </div>
        <div className="col-10 col-md-8 mb-3">
          {terminal === "customization" && (
            <TerminalFormCompent
              handleCustomizedTerminalDataChange={
                handleCustomizedTerminalDataChange
              }
              customizedTerminalData={customizedTerminalData}
            />
          )}
        </div>
      </div>
      <OptionsCompent
        terminal={terminal}
        customizedTerminalData={customizedTerminalData}
      ></OptionsCompent>
    </div>
  );
};

export default Container;
