export async function payment_onboardCustomer_showQrcode(actionService) {
  const status = await actionService.askAmountPayment();
  if (status === false) {
    return false;
  }

  return await actionService.postAction({
    action: "display-showQRCode",
    parameters: {
      qrcodeValue: "https://www.loyyo.nl/",
    },
  });
}
