import React, { useState } from "react";
import axios from "axios";
import { SketchPicker } from "react-color";
import * as _ from "lodash";

const NfcPassFormComponse = (props) => {
  const [email, setEmail] = useState("");
  const [webServiceURL, setWebServiceURL] = useState("");
  const [logoText, setLogoText] = useState("");
  const [passHolderName, setPassHolderName] = useState("");
  const [foregroundColor, setForegroundColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [displayForegroundColorPicker, setDisplayForegroundColorPicker] =
    useState(false);
  const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [logoFile, setLogoFile] = useState("/logo@2x.png");
  const [stripFile, setStripFile] = useState("/strip.png");
  const stripFileInput = React.createRef();
  const logoFileInput = React.createRef();

  const handleForegroundClick = () => {
    setDisplayForegroundColorPicker(!displayForegroundColorPicker);
  };

  const handleForegroundClose = () => {
    setDisplayForegroundColorPicker(false);
  };
  const handleBackgroundClick = () => {
    setDisplayBackgroundColorPicker(!displayBackgroundColorPicker);
  };

  const handleBackgroundClose = () => {
    setDisplayBackgroundColorPicker(false);
  };

  const handleChangeForegroundColor = (color) => {
    setForegroundColor(color.hex);
  };
  const handleChangeBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      alert("email is required");
      return;
    }

    if (!passHolderName) {
      alert("PassHolderName is required");
      return;
    }

    setIsProcessing(true);

    const bodyFormData = new FormData();
    const baseUrl = process.env.REACT_APP_API_URL;
    bodyFormData.append("webServiceURL", webServiceURL || baseUrl);
    bodyFormData.append("logoText", logoText);
    bodyFormData.append("passHolderName", passHolderName);
    if (stripFileInput.current) {
      bodyFormData.append("strip", stripFileInput.current?.files[0]);
    }
    if (logoFileInput.current) {
      bodyFormData.append("logo", logoFileInput.current?.files[0]);
    }
    bodyFormData.append("foregroundColor", foregroundColor);
    bodyFormData.append("backgroundColor", backgroundColor);

    await axios({
      method: "post",
      url: `${baseUrl}/applepass${email ? "?email=" + email : ""}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        const url = _.get(response.data, "downloadUrl");
        if (url) {
          window.location = url;
        } else {
          alert("Try it again");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <form onSubmit={onFormSubmit} autoComplete="off">
      <div className="mb-3">
        <div className="row d-flex justify-content-center">
          <div
            className="p-0 col-3 bg-blue"
            style={{
              background: backgroundColor,
              width: "250px",
              height: "295px",
              borderRadius: "10px",
            }}
          >
            <div
              className="title"
              style={{
                width: "100%",
                height: "40px",
              }}
            >
              <img
                src={logoFile}
                style={{
                  padding: "2%",
                  height: "95%",
                }}
              />
              <span style={{ color: foregroundColor }}>{logoText}</span>
            </div>
            <div
              className="image"
              style={{
                width: "100%",
                height: "80px",
              }}
            >
              <img
                src={stripFile}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div className="main d-flex justify-content-between p-2">
              <div>
                <p
                  style={{
                    color: foregroundColor,
                    fontSize: "12px",
                  }}
                  className="m-0"
                >
                  MEMBER NAME:
                </p>
                <p
                  style={{
                    color: foregroundColor,
                    wordWrap: "anywhere",
                    fontSize: "16px",
                  }}
                >
                  {passHolderName}
                </p>
              </div>
              <div>
                <p
                  style={{
                    color: foregroundColor,
                    fontSize: "12px",
                  }}
                  className="m-0"
                >
                  VALID TO
                </p>
                <p
                  style={{
                    color: foregroundColor,
                    fontSize: "16px",
                  }}
                >
                  12/2030
                </p>
              </div>
            </div>
          </div>
        </div>

        <label htmlFor="email" className="form-label">
          Member email address <label className="text-danger">*</label>
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          className="form-control"
          id="email"
        />

        <label htmlFor="passHolderName" className="form-label">
          First name of pass holder <label className="text-danger">*</label>
        </label>
        <input
          value={passHolderName}
          onChange={(e) => setPassHolderName(e.target.value)}
          type="text"
          className="form-control"
          id="passHolderName"
        />

        <label htmlFor="logoText" className="form-label">
          Text next to logo
        </label>
        <input
          value={logoText}
          onChange={(e) => setLogoText(e.target.value)}
          type="text"
          className="form-control"
          id="logoText"
        />

        <div className="my-2">
          <label htmlFor="logo" className="form-label">
            Logo image (PNG, square logo: 100 x 100, rectangle logo: max 320 x
            100px)
          </label>
          <input
            className="form-control"
            type="file"
            id="logo"
            ref={logoFileInput}
            onChange={(e) => {
              setLogoFile(URL.createObjectURL(e.target.files[0]));
            }}
            accept="image/png"
          />
        </div>

        <label htmlFor="strip" className="form-label">
          Choose image (PNG with size ratio of 624x245px). Leave empty for our
          default background.
        </label>
        <input
          className="form-control"
          type="file"
          id="strip"
          ref={stripFileInput}
          onChange={(e) => {
            setStripFile(URL.createObjectURL(e.target.files[0]));
          }}
          accept="image/png"
        />

        <div className="d-flex align-items-center mt-2 md-3">
          Pass text color:
          <div
            style={{
              marginLeft: "10px",
              padding: "5px",
              background: "#fff",
              borderRadius: "1px",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              cursor: "pointer",
            }}
            onClick={handleForegroundClick}
          >
            <div
              style={{
                backgroundColor: foregroundColor,
                width: "36px",
                height: "14px",
                borderRadius: "2px",
              }}
            />
          </div>
          {displayForegroundColorPicker ? (
            <div
              style={{
                position: "absolute",
                zIndex: "2",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={handleForegroundClose}
              />
              <SketchPicker
                color={foregroundColor}
                onChange={handleChangeForegroundColor}
              />
            </div>
          ) : null}
        </div>

        <div className="d-flex align-items-center mt-2 md-3">
          Pass background color:
          <div
            style={{
              marginLeft: "10px",
              padding: "5px",
              background: "#fff",
              borderRadius: "1px",
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
              cursor: "pointer",
            }}
            onClick={handleBackgroundClick}
          >
            <div
              style={{
                backgroundColor: backgroundColor,
                width: "36px",
                height: "14px",
                borderRadius: "2px",
              }}
            />
          </div>
          {displayBackgroundColorPicker ? (
            <div
              style={{
                position: "absolute",
                zIndex: "2",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={handleBackgroundClose}
              />
              <SketchPicker
                color={backgroundColor}
                onChange={handleChangeBackgroundColor}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="submit"
          className="btn btn-lg start-btn"
          disabled={isProcessing}
        >
          DOWNLOAD PKPASS
        </button>
      </div>
    </form>
  );
};

export default NfcPassFormComponse;
