import React from "react";
const Header = (props) => {
  const title = props.title || "Flow Creator";

  return (
    <div id="header" className="header-block">
      <img src="/Adyen-Logo.svg" className="icon-block" />
      <div className="title-block row d-flex flex-column justify-content-evenly align-items-center">
        <p className="text-center py-3">{title}</p>
      </div>
      <img src="/LOYYO-logo-landscape.svg" className="icon-block-1" />
    </div>
  );
};

export default Header;
