export async function nfcMifatre_roomKey_showData(actionService) {
  const card = await actionService.cardAcquisition();
  const uid = card.cardId;
  const roomNumber = localStorage.getItem(`roomKey-${uid}`);

  if (uid && roomNumber) {
    await actionService.showEnableMessage(
      "Welcome!",
      `Room ${roomNumber}`,
      "Accepted"
    );
  } else {
    await actionService.showEnableMessage(
      "",
      `Card not recognized!`,
      "Declined"
    );
  }
}
