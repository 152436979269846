export async function cardAcquistion_loyalty_add_stamps(actionService) {
  const card = await actionService.cardAcquisition();
  const stamps = localStorage.getItem(`loyalty-${card.cardId}-stamps`) * 1 || 0;

  const newStamps = stamps + 1;
  localStorage.setItem(`loyalty-${card.cardId}-stamps`, newStamps);


  // get stampBrand from URL query parameter
  const demo = new URLSearchParams(window.location.search).get("demo");
  const stampBrand = demo || process.env.REACT_APP_STAMP_BRAND || "dunking";

  if (newStamps === 0) {
    await actionService.showStamps({
      stampName: `${stampBrand}-0`,
    });
  } else {
    const showStampsNum = newStamps % 3 || 3;

    if (showStampsNum === 3) {
      await actionService.showStamps({
        stampName: `${stampBrand}-full`,
      });
    } else {
      await actionService.showStamps({
        stampName: `${stampBrand}-${showStampsNum + 1}-process`,
      });
    }
  }

  await new Promise((resolve) => setTimeout(resolve, 5000));
  return await actionService.showEnableMessage(
    "Loyalty",
    `You have ${newStamps} stamps in total now.`,
    "Accepted"
  );
}
