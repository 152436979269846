import axios from "axios";
export async function nfcPass_applePassRecognize_recognize(actionService) {
  const card = await actionService.cardAcquisition();

  let passInformation = null;
  if (card.LoyaltyID) {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/applePassRecognize`,
      data: {
        loyaltyID: card.LoyaltyID,
      },
    });
    if (response.data?.passInformation) {
      passInformation = response.data?.passInformation;
    }
  }

  if (passInformation) {
    await actionService.showEnableMessage(
      `Hi, ${passInformation.passHolderName}`,
      `Welcome at LOYYO ${passInformation.passHolderName}`,
      "Accepted"
    );
  } else {
    await actionService.showEnableMessage(
      "",
      `Card not recognized!`,
      "Declined"
    );
  }
  return null;
}
