import React,{ useEffect,useState } from "react";

import { useSearchParams } from "react-router-dom";
import * as axios from "axios";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

const AdyenWebTest = () => {
  const [searchParams] = useSearchParams();
  const [amount,setAmount] = useState(0);
  const [paymentMethod,setPaymentMethod] = useState(null);

  const onInputChange = (event) => {
    setAmount(event.target.value);
  };

  const getPaymentMethods = async () => {
    const response = await axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {},
      })
      .post("adyenPaymentMethodsTest",{ amount: (amount || 0) * 100 });
    console.log("response->",response);

    return response.data?.paymentMethods;
  };

  const payment = async () => {
    console.log(paymentMethod);
    const response = await axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {},
      })
      .post("adyenPaymentsTest",{ amount: (amount || 0) * 100,paymentMethod });
    const action = response.data?.action;
    const url = action?.url;
    if (url) {
      window.location = url;
    }
    console.log("response->",response);

    // return response.data?.paymentMethods;
  };

  const adyenCheckout = async () => {
    const paymentMethods = await getPaymentMethods();
    const configuration = {
      locale: "nl-NL", // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/online-payments/web-components/localization-components.
      environment: "test", // When you're ready to accept live payments, change the value to one of our live environments https://docs.adyen.com/online-payments/components-web#testing-your-integration.
      clientKey: "test_F4ZU27HDZFBPPC2N26YEOP7ZOUCRDTPI", // Your client key. To find out how to generate one, see https://docs.adyen.com/development-resources/client-side-authentication. Web Components versions before 3.10.1 use originKey instead of clientKey.
      paymentMethodsResponse: {
        paymentMethods: paymentMethods,
      }, // The payment methods response returned in step 1.
      onChange: (state,component) => {
        console.log(state.isValid); // True or false. Specifies if all the information that the shopper provided is valid.
        setPaymentMethod(state.data?.paymentMethod);
        console.log(state.data); // Provides the data that you need to pass in the `/payments` call.
        console.log(component); // Provides the active component instance that called this event.
      }, // Your function for handling onChange event
      onAdditionalDetails: (state,component) => {
        console.log(state.data); // Provides the data that you need to pass in the `/payments/details` call.
        console.log(component); // Provides the active component instance that called this event.
      }, // Your function for handling onAdditionalDetails event
    };

    AdyenCheckout(configuration).then((checkout) => {
      checkout.create("ideal").mount("#ideal-container");
    });
  };

  // Create an instance of AdyenCheckout using the configuration object.

  // Access the available payment methods for the session.
  // console.log(checkout.paymentMethodsResponse); // => { paymentMethods: [...], storedPaymentMethods: [...] }

  // Create an instance of the Component and mount it to the container you created.

  return (
    <div className="container">
      <div>
        <h3>Step 1. Amount (€{amount})</h3>
        <div className="input-group mb-3">
          <span className="input-group-text">€</span>
          <input
            type="text"
            className="form-control"
            aria-label="Amount"
            onChange={onInputChange}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={adyenCheckout}
        >
          Submit
        </button>
      </div>
      <br />
      <div>
        <h3>Step 2. Choose payment</h3>
        <div id="ideal-container"></div>
        {(paymentMethod &&
          <button type="button" className="btn btn-primary" onClick={payment}>
            Payment
          </button>
        )}
      </div>
      <div>
        <h3>Step 3. Payment Result</h3>
        <div id="result-container">{searchParams.get("resultCode")}</div>
      </div>
    </div>
  );
};

export default AdyenWebTest;
