export async function cardAcquistion_loyalty_points(actionService) {
  const card = await actionService.cardAcquisition();
  const points = localStorage.getItem(`loyalty-${card.cardId}-points`) * 1 || 0;

  return await actionService.showEnableMessage(
    "Loyalty",
    `You have ${points} points in total now.`,
    "Accepted"
  );
}
