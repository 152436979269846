export async function payment_loyalty_points(actionService) {

  const paymentResponse = await actionService.askAmountPayment();

  if (paymentResponse === false) {
    await actionService.showEnableMessage(
      "",
      `Payment failed`,
      "Declined"
    );
  }

  if (paymentResponse.authorizedAmount) {
    const addPoints = paymentResponse.authorizedAmount * 200;

    const cardAlias = paymentResponse.cardId;
    const originPoints =
      localStorage.getItem(`loyalty-${cardAlias}-points`) * 1;
    let afterPoints = 0;
    afterPoints = originPoints + addPoints;
    if (originPoints) {
      localStorage.setItem(`loyalty-${cardAlias}-points`, afterPoints);
    } else {
      localStorage.setItem(`loyalty-${cardAlias}-points`, addPoints);
    }

    await actionService.showConfirmationMessage(
      "Loyalty points!",
      `${addPoints} points added, total points: ${afterPoints}`,
    );
  }
}
