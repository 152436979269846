import React from "react";
import flowService from "../../services/flowService";
import NfcPassFormComponse from "./NfcPassFormComponse";

const StartFlowCompent = (props) => {
  const action = props.action;
  const terminal = props.terminal;
  const customizedTerminalData = props.customizedTerminalData;

  let Content = ""
  if (action === "nfcPass_applePassCreate") {
    Content = (
      <div className="col-6">
        <NfcPassFormComponse />
      </div>
    );
  } else {
    Content = (
      <div className="d-flex justify-content-center">
        <button
          onClick={() =>
            flowService(
              { name: terminal,data: customizedTerminalData },
              action
            )
          }
          type="button"
          className="btn btn-lg start-btn"
        >
          START FLOW
        </button>
      </div>
    );
  }

  return (<div className="d-flex justify-content-center">
    {Content}
  </div>)
};

export default StartFlowCompent;
