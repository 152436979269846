export async function cardAcquistion_loyalty_stamps(actionService) {
  const card = await actionService.cardAcquisition();
  const stamps = localStorage.getItem(`loyalty-${card.cardId}-stamps`) * 1 || 0;
  const stampBrand = process.env.REACT_APP_STAMP_BRAND || "dunking";

  if (stamps === 0) {
    await actionService.showStamps({
      stampName: `${stampBrand}-0`,
    });
  } else {
    const showStampsNum = stamps % 3 || 3;

    if (showStampsNum === 3) {
      await actionService.showStamps({
        stampName: `${stampBrand}-full`,
      });
    } else {
      await actionService.showStamps({
        stampName: `${stampBrand}-${showStampsNum + 1}-process`,
      });
    }
  }

  await new Promise((resolve) => setTimeout(resolve, 5000));
  return await actionService.showEnableMessage(
    "Loyalty",
    `You have ${stamps} stamps in total now.`,
    "Accepted"
  );
}
