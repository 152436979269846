import * as _ from "lodash";

export async function nfcMifatre_roomKey_putOnAccount(actionService) {
  const card = await actionService.cardAcquisition();
  const uid = card.cardId;
  const roomNumber = localStorage.getItem(`roomKey-${uid}`);

  if (uid && roomNumber) {
    const actionResponse = await actionService.postAction({
      action: "input-confirmation",
      parameters: {
        title: "Do you want to add this bill to room?",
        agreeText: "Yes",
        declineText: "No",
      },
    });
    if (_.get(actionResponse, "result") === true) {
      const signResponse = await actionService.postAction({
        action: "input-signature",
        title: "",
      });
      console.log("signResponse=>", signResponse);
      if (_.get(signResponse, "result")) {
        await actionService.showEnableMessage(
          "Success",
          `Bill added to your room.`,
          "Accepted"
        );
      } else {
        await actionService.showEnableMessage("Cancelled", "", "Accepted");
      }
    } else {
      await actionService.showEnableMessage("Cancelled", "", "Accepted");
    }
  } else {
    await actionService.showEnableMessage(
      "",
      `Card not recognized!`,
      "Declined"
    );
  }
}
