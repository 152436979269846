import * as _ from "lodash";

export async function payment_onboardCustomer_askToOnboard(actionService) {
  const status = await actionService.askAmountPayment();
  if (status === false) {
    return false;
  }

  const actionResponse = await actionService.postAction({
    action: "input-confirmation",
    parameters: {
      title: "Do you want to onboard?",
      agreeText: "Yes",
      declineText: "No",
    },
  });
  let title = "Loyalty member";
  let content = "";
  let icon = "";
  let phone = "";
  if (_.get(actionResponse, "result") === true) {
    content = "\n\n\nYou are now a loyalty member!";
    icon = "Accepted";
    await actionService.postAction({
      action: "input-phoneNumber",
      title: "Enter PhoneNumber",
    });
  } else {
    content = "\n\n\nThank you for your purchase message.";
  }
  await actionService.showConfirmationMessage(title, content);
}
