export async function nfcMifatre_roomKey_register(actionService) {
  const card = await actionService.cardAcquisition();

  if (card.type === "nfc" && card.cardId) {
    const inputResponse = await actionService.postAction({
      action: "input-digit",
      parameters: {
        title: "Enter Room Number",
      },
    });
    const roomNumber = inputResponse.result;
    if (roomNumber) {
      localStorage.setItem(`roomKey-${card.cardId}`, roomNumber);
    }
    await actionService.showEnableMessage("Success", "Registered!", "Accepted");
  } else {
    await actionService.showEnableMessage(
      "",
      "Card not recognized!",
      "Declined"
    );
  }
}
