import * as _ from "lodash";

export async function cardAcquistion_onboardCustomer_askToOnboard(
  actionService
) {
  const card = await actionService.cardAcquisition();
  const actionResponse = await actionService.postAction({
    action: "input-confirmation",
    parameters: {
      title: "Do you want to onboard?",
      agreeText: "Yes",
      declineText: "No",
    },
  });
  let title = "Loyalty member";
  let content = "";
  let icon = "";
  let phone = "";
  if (_.get(actionResponse, "result") === true) {
    content = "You are now a loyalty member!";
    icon = "Accepted";
    await actionService.postAction({
      action: "input-phoneNumber",
      title: "Enter PhoneNumber",
    });
  } else {
    content = "Thank you for your purchase message.";
  }

  title = "Loyalty";
  await actionService.showEnableMessage(title, content, icon);
}
