export async function cardAcquistion_loyalty_redeem(actionService) {
  const card = await actionService.cardAcquisition();
  const points = localStorage.getItem(`loyalty-${card.cardId}-points`) * 1 || 0;

  const inputResponse = await actionService.postAction({
    action: "input-digit",
    parameters: {
      title: "Redeem the points",
    },
  });
  const redeemPoints = inputResponse.result;
  if (redeemPoints) {
    const afterPoints = points - redeemPoints;
    if (afterPoints >= 0) {
      localStorage.setItem(`loyalty-${card.cardId}-points`, afterPoints);
      await actionService.showEnableMessage(
        "Loyalty",
        `${redeemPoints} points redeemed, total points: ${afterPoints}`,
        "Accepted"
      );
    } else {
      return await actionService.showEnableMessage(
        "Loyalty",
        `You don't have enough points.`,
        "Declined"
      );
    }
  }
}
