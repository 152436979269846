import React, { useState } from "react";

import { useSearchParams } from "react-router-dom";
import * as axios from "axios";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

const EcomFlowCompent = () => {
  const [searchParams] = useSearchParams();
  const [env, setEnv] = useState("test");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const onEnvChange = (event) => {
    setEnv(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const adyenCheckout = async () => {
    if (!email) {
      alert("Email is required");
      return;
    }
    if (!amount) {
      alert("Amount is required");
      return;
    }

    const paymentMethods = await getPaymentMethods();
    const configuration = {
      locale: "nl-NL", //
      environment: env === "live" ? "live" : "test",
      clientKey:
        env === "live"
          ? "live_ALRW2HX23RB6XPZA4XF6LB7F4IXIBZF2"
          : "test_F4ZU27HDZFBPPC2N26YEOP7ZOUCRDTPI",
      paymentMethodsResponse: {
        paymentMethods: paymentMethods,
      },
      onChange: (state, component) => {
        console.log(state.isValid);
        setPaymentMethod(state.data?.paymentMethod);
        console.log(state.data);
        console.log(component);
      },
      onAdditionalDetails: (state, component) => {
        console.log(state.data);
        console.log(component);
      },
    };

    AdyenCheckout(configuration).then((checkout) => {
      checkout.create("ideal").mount("#ideal-container");
    });
  };

  const getPaymentMethods = async () => {
    const response = await axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {},
      })
      .post("adyenEcomPaymentMethods", {
        env,
        amount: (amount || 0) * 100,
      });
    console.log("response->", response);

    return response.data?.paymentMethods;
  };

  const payment = async () => {
    const response = await axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {},
      })
      .post("adyenEcomPayments", {
        env,
        email,
        amount: (amount || 0) * 100,
        paymentMethod,
      });
    const action = response.data?.action;
    const url = action?.url;
    if (url) {
      window.location = url;
    }
    console.log("response->", response);

    // return response.data?.paymentMethods;
  };

  return (
    <div className="container col-10">
      <h3>Step 1. Test / LIVE</h3>
      <div className="mb-3">
        <label htmlFor="inputHost" className="form-label me-3">
          ENV
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="env"
            id="inlineRadio1"
            value="test"
            onChange={onEnvChange}
            defaultChecked
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            TEST
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="env"
            id="inlineRadio2"
            value="live"
            onChange={onEnvChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            LIVE
          </label>
        </div>
      </div>
      <h3>Step 2. Email / Amount (€{amount})</h3>
      <div className="input-group mb-3">
        <span className="input-group-text">Email</span>
        <input
          type="text"
          className="form-control"
          aria-label="Email"
          onChange={onEmailChange}
        />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text">€</span>
        <input
          type="text"
          className="form-control"
          aria-label="Amount"
          onChange={onAmountChange}
        />
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={adyenCheckout}
        >
          Submit
        </button>
      </div>
      <br />
      <div>
        <h3>Step 3. Choose payment</h3>
        <div id="ideal-container"></div>
        {paymentMethod && (
          <div className="d-flex justify-content-center my-2">
            <button type="button" className="btn btn-primary" onClick={payment}>
              Payment
            </button>
          </div>
        )}
      </div>
      <div>
        <h3>Step 4. Payment Result</h3>
        <div id="result-container" className="d-flex justify-content-center">
          {searchParams.get("resultCode") === "Authorised" ? (
            <div className="text-center">
              <h3>Authorised</h3>
              <p>Add Points: {searchParams.get("addPoints")}</p>
              <p>Total Points: {searchParams.get("totalPoints")}</p>
            </div>
          ) : (
            <h3>{searchParams.get("resultCode")}</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default EcomFlowCompent;
