import { actionService } from "./actionService";
import * as actions from "./actions/index";

export default async (terminal, actionName) => {
  try {
    const action = actions[actionName];
    if (action) {
      actionService.initTerminal(terminal);
      await action(actionService);
    } else {
      alert(`${actionName} not yet build`);
    }
  } catch (error) {
    alert(error.message);
  }
};
