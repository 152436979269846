export async function payment_onboardCustomer_printReceipt(actionService) {
  const status = await actionService.askAmountPayment();
  if (status === false) {
    return false;
  }

  return await actionService.postAction({
    action: "print-receipt",
    parameters: {
      BarcodeValue: "https://www.loyyo.nl/",
    },
  });
}
