export async function payment_loyalty_stamps(actionService) {
  const amount = 0.01;
  const addStamps = 1;
  const paymentResponse = await actionService.paymentRequest(amount);
  const stampBrand = process.env.REACT_APP_STAMP_BRAND || "dunking";

  if (paymentResponse.result === "Success") {
    const cardAlias = paymentResponse.cardId;
    const originStamps =
      localStorage.getItem(`loyalty-${cardAlias}-stamps`) * 1;
    let afterStamps = 0;
    afterStamps = originStamps + addStamps;
    localStorage.setItem(`loyalty-${cardAlias}-stamps`, afterStamps);

    const showStampsNum = afterStamps % 3 || 3;

    await new Promise((resolve) => setTimeout(resolve, 5000));

    await actionService.showStamps({
      stampName: `${stampBrand}-${showStampsNum}-process`,
    });
    await actionService.showStamps({
      stampName: `${stampBrand}-${showStampsNum}`,
    });
    await new Promise((resolve) => setTimeout(resolve, 5000));

    await actionService.postAction({
      action: "display-showStandby",
    });
  }
}
