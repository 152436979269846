export const optionsData = [
  {
    id: 0,
    parentId: null,
    name: "payment",
    displayName: "PAYMENT",
    action: "",
    nextStepTitle: "3. What needs to happen after the payment",
  },
  {
    id: 1,
    parentId: null,
    name: "nfcMifatre",
    displayName: "MIFARE CARD",
    action: "",
    nextStepTitle: "3. Select your next step",
  },
  {
    id: 2,
    parentId: null,
    name: "cardAcquistion",
    displayName: "CARD ACQUISTION",
    action: "",
    nextStepTitle: "3. Select your next step",
  },
  {
    id: 3,
    parentId: null,
    name: "nfcPass",
    displayName: "NFC WALLET pass",
    action: "",
    nextStepTitle: "3. Select your next step",
  },
  {
    id: 4,
    parentId: 0,
    name: "onboardCustomer",
    displayName: "ONBOARD\nCUSTOMER",
    action: "",
    nextStepTitle: "4. How do you want to onboard",
  },
  {
    id: 5,
    parentId: 0,
    name: "loyalty",
    displayName: "LOYALTY",
    action: "",
    nextStepTitle: "4. Finalize your flow",
  },
  {
    id: 6,
    parentId: 0,
    name: "donation",
    displayName: "DONATION",
    action: "",
    nextStepTitle: "4. Finalize your flow",
  },
  {
    id: 7,
    parentId: 4,
    name: "askToOnboard",
    displayName: "ASK PHONE NR",
    action: "payment_onboardCustomer_askToOnboard",
  },
  {
    id: 8,
    parentId: 4,
    name: "showQrcode",
    displayName: "SHOW QR CODE",
    action: "payment_onboardCustomer_showQrcode",
  },
  {
    id: 9,
    parentId: 5,
    name: "points",
    displayName: "POINTS\nAFTER PAYMENT",
    action: "payment_loyalty_points",
  },
  {
    id: 10,
    parentId: 5,
    name: "stamps",
    displayName: "STAMPS\nAFTER PAYMENT",
    action: "payment_loyalty_stamps",
  },
  {
    id: 11,
    parentId: 6,
    name: "askToDonate",
    displayName: "ASK TO DONATE",
    action: "payment_donation_askToDonate",
  },
  {
    id: 12,
    parentId: 6,
    name: "donateWithOnboard",
    displayName: "DONATE\nWITH ONBOARD",
    action: "payment_donation_donateWithOnboard",
  },
  {
    id: 13,
    parentId: 1,
    name: "roomKey",
    displayName: "HOTEL ROOMKEY",
    action: "",
    nextStepTitle: "4. What do you want to do with the roomkey?",
  },
  {
    id: 14,
    parentId: 13,
    name: "register",
    displayName: "CONNECT\nTO A ROOM",
    action: "nfcMifatre_roomKey_register",
  },
  {
    id: 15,
    parentId: 13,
    name: "showData",
    displayName: "SHOW\nCONNECT ROOM",
    action: "nfcMifatre_roomKey_showData",
  },
  {
    id: 16,
    parentId: 13,
    name: "putOnAccount",
    displayName: "SIGN TO\nPUT ON ROOM",
    action: "nfcMifatre_roomKey_putOnAccount",
  },
  {
    id: 17,
    parentId: 2,
    name: "onboardCustomer",
    displayName: "ONBOARD\nCUSTOMER",
    action: "",
    nextStepTitle: "4. Finalize your flow",
  },
  {
    id: 18,
    parentId: 2,
    name: "loyalty",
    displayName: "LOYALTY",
    action: "",
    nextStepTitle: "4. What do you want to show on the terminal?",
  },
  {
    id: 19,
    parentId: 17,
    name: "askToOnboard",
    displayName: "ASK TO ONBOARD",
    action: "cardAcquistion_onboardCustomer_askToOnboard",
  },
  {
    id: 20,
    parentId: 18,
    name: "points",
    displayName: "Loyalty\nPoints",
    action: "cardAcquistion_loyalty_points",
  },
  {
    id: 21,
    parentId: 18,
    name: "stamps",
    displayName: "Stamps\non Loyalty card",
    action: "cardAcquistion_loyalty_stamps",
  },
  {
    id: 22,
    parentId: 18,
    name: "redeem",
    displayName: "Input to\nRedeem points",
    action: "cardAcquistion_loyalty_redeem",
  },
  {
    id: 23,
    parentId: 3,
    name: "applePassCreate",
    displayName: "CREATE\nAPPLE NFC PASS",
    action: "nfcPass_applePassCreate",
  },
  {
    id: 24,
    parentId: 3,
    name: "applePassRecognize",
    displayName: "RECOGNIZE\nAPPLE NFC PASS",
    action: "nfcPass_applePassRecognize_recognize",
  },
  {
    id: 25,
    parentId: 4,
    name: "printReceipt",
    displayName: "PRINT RECEIPT",
    action: "payment_onboardCustomer_printReceipt",
  },
  {
    id: 26,
    parentId: 13,
    name: "showRoomNumber",
    displayName: "Van der Valk",
    action: "nfcMifatre_roomKey_showRoomNumber",
  },
  {
    id: 25,
    parentId: 18,
    name: "points",
    displayName: "Point After Tap",
    action: "cardAcquistion_loyalty_add_points",
  },
  {
    id: 26,
    parentId: 18,
    name: "stamps",
    displayName: "Stamp After Tap",
    action: "cardAcquistion_loyalty_add_stamps",
  },
];