import * as _ from "lodash";

export async function payment_donation_askToDonate(actionService) {
  const actionResponse = await actionService.postAction({
    action: "input-confirmation",
    parameters: {
      title: "Would you like to donate?",
      agreeText: "Yes",
      declineText: "No",
    },
  });
  if (_.get(actionResponse, "result") === true) {
    const inputAmountResponse = await actionService.postAction({
      action: "input-amount",
      parameters: {
        title: "Enter the amount",
      },
    });
    const amount = inputAmountResponse.result;

    const paymentResponse = await actionService.cardAcquisitionWithPayment(
      amount
    );

    if (paymentResponse === false) {
      await actionService.showEnableMessage(
        "",
        `Cannot get your cardAlias`,
        "Declined"
      );
    }

    if (paymentResponse.result === "Success") {
      await actionService.showEnableMessage(
        "Thanks",
        "Thank you for your donation",
        "Accepted"
      );
    }
  } else {
    await actionService.showConfirmationMessage(
      "Thanks",
      "\n\n\nThank you for your purchase message"
    );
  }
}
