import React from "react";

export default (props) => {
  const options = props.options;
  const groupName = props.groupName;
  const optionSeleted = props.optionSeleted;

  return (
    <section
      className={
        "cf select-block my-4 d-flex flex-wrap justify-content-center colorful-label"
      }
    >
      {options.map((option) => {
        return (
          <div className="m-3" key={option.id}>
            <input
              name={groupName}
              type="radio"
              id={option.id}
              value={option.id}
              onChange={(e) => {
                optionSeleted(e.target.value);
              }}
            />
            <label className="select-label" htmlFor={option.id}>
              {option.displayName}
            </label>
          </div>
        );
      })}
    </section>
  );
};
