import * as loyyoAdyen from "../apis/loyyoAdyen";
import * as _ from "lodash";

let terminal = "";

export const actionService = {
  initTerminal: (setTerminal) => {
    terminal = {
      name: setTerminal.name,
      data: {
        terminalid: setTerminal.data.terminalid || "",
        apikey: setTerminal.data.apikey || "",
        host: setTerminal.data.host || "",
      },
    };
  },
  showStamps: async (object) => {
    return await loyyoAdyen.showStamps(terminal, object);
  },
  postAction: async (object) => {
    return await loyyoAdyen.postAction(terminal, object);
  },
  // askAmountThenPayment
  askAmountPayment: async () => {
    const inputResponse = await loyyoAdyen.postAction(terminal, {
      action: "input-amount",
      parameters: {
        title: "Enter the amount",
      },
    });

    if (inputResponse.result === undefined) {
      return false;
    }

    const paymentResponse = await loyyoAdyen.postAction(terminal, {
      action: "payment-paymentRequest",
      parameters: {
        amount: inputResponse.result,
      },
    });

    const Result = _.get(
      paymentResponse,
      "response.SaleToPOIResponse.PaymentResponse.Response.Result"
    );
    if (Result !== "Success") {
      return false;
    }

    const AuthorizedAmount = _.get(
      paymentResponse,
      "response.SaleToPOIResponse.PaymentResponse.PaymentResult.AmountsResp.AuthorizedAmount"
    );

    return {
      authorizedAmount: AuthorizedAmount,
    };
  },
  cardAcquisitionWithPayment: async (amount) => {
    const actionResponse = await loyyoAdyen.postAction(terminal, {
      action: "acquisition-cardRequest",
      parameters: {
        amount,
      },
    });
    const Result = _.get(
      actionResponse,
      "response.SaleToPOIResponse.CardAcquisitionResponse.Response.Result"
    );
    const getAdditionalResponse = _.get(
      actionResponse,
      "response.SaleToPOIResponse.CardAcquisitionResponse.Response.AdditionalResponse"
    );
    const nestedCardData = new URLSearchParams(getAdditionalResponse);
    const memberCardId = nestedCardData.get("alias");
    if (memberCardId) {
      return {
        cardId: memberCardId,
        result: Result,
      };
    }
    return false;
  },
  cardAcquisition: async () => {
    const actionResponse = await loyyoAdyen.postAction(terminal, {
      action: "acquisition-cardRequest",
      parameters: {},
    });
    const getAdditionalResponse = _.get(
      actionResponse,
      "response.SaleToPOIResponse.CardAcquisitionResponse.Response.AdditionalResponse"
    );
    const nestedCardData = new URLSearchParams(getAdditionalResponse);
    const memberCardId =
      nestedCardData.get("NFC.uid") || nestedCardData.get("alias");
    const LoyaltyID =
      _.get(
        actionResponse,
        "response.SaleToPOIResponse.CardAcquisitionResponse.LoyaltyAccount[0].LoyaltyAccountID.LoyaltyID"
      ) || null;
    if (nestedCardData.get("alias")) {
      return {
        cardId: memberCardId,
        type: "payment",
        LoyaltyID,
      };
    } else if (nestedCardData.get("NFC.uid")) {
      return {
        cardId: memberCardId,
        type: "nfc",
      };
    } else if (LoyaltyID) {
      return {
        LoyaltyID,
        type: "memberCard",
      };
    }
    return false;
  },
  paymentRequest: async (amount) => {
    const actionResponse = await loyyoAdyen.postAction(terminal, {
      action: "payment-paymentRequest",
      parameters: {
        title: "Payment",
        amount: amount,
      },
    });

    const getAdditionalResponse = _.get(
      actionResponse,
      "response.SaleToPOIResponse.PaymentResponse.Response.AdditionalResponse"
    );
    const nestedCardData = new URLSearchParams(getAdditionalResponse);
    const cardId = nestedCardData.get("alias");

    const result = _.get(
      actionResponse,
      "response.SaleToPOIResponse.PaymentResponse.Response.Result"
    );

    return {
      cardId: cardId,
      result: result,
    };
  },
  showConfirmationMessage: async (title, content) => {
    return await loyyoAdyen.postAction(terminal, {
      action: "input-confirmation",
      parameters: {
        title: title,
        content: content,
        agreeText: "OK",
      },
    });
  },
  showEnableMessage: async (title, content, icon = "Idle") => {
    return await loyyoAdyen.postAction(terminal, {
      action: "enable-enableServiceRequest",
      parameters: {
        icon: icon,
        title: title,
        content: content,
      },
    });
  },
  showRoomNumberFromVanderValk: async () => {
    const actionResponse = await loyyoAdyen.postGetRoomNumberFromVanderValk(
      terminal
    );

    return actionResponse;
  },
};
